import { useEffect, useRef, useState } from "react";

import { Classes, Colors, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import {
  useFloating,
  useInteractions,
  useHover,
  useClick,
  useDismiss,
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  safePolygon,
  useTransitionStyles,
  FloatingArrow,
} from "@floating-ui/react";
import { createPortal } from "react-dom";

import {
  getFallbackPlacements,
  getTransformOrigin,
  positionToPlacement,
} from "./utils";

import type { PopoverProps } from "./props";

const ARROW_PADDING = 4;
const OFFSET_DISTANCE = 10;
const SVG_ARROW_PATH =
  "M0 20C0 20 2.06906 19.9829 5.91817 15.4092C7.49986 13.5236 8." +
  "97939 12.3809 10.0002 12.3809C11.0202 12.3809 12.481 13.6451 14.0814 15.5472C17.952 20.1437 20 20 20 20H0Z";

const PopoverContainer = styled.div<{
  minimal?: boolean;
  maxHeight?: string;
}>`
  position: fixed;
  z-index: 99999;
  background: ${({ minimal }) => (minimal ? "transparent" : Colors.WHITE)};
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

  // Blueprint Menu styles
  .bp5-menu {
    background: ${Colors.WHITE};
    border-radius: 6px;
    padding: ${({ minimal }) => (minimal ? "0" : "5px")};
    min-width: 180px;
  }

  .bp5-menu-item {
    border-radius: 3px;
    padding: 8px 12px;
    color: ${Colors.DARK_GRAY1};
    transition: background-color 100ms;

    &:hover {
      background: rgba(167, 182, 194, 0.3);
      cursor: pointer;
      color: ${Colors.DARK_GRAY1};
    }
  }

  .bp5-menu-divider {
    border-top: 1px solid ${Colors.LIGHT_GRAY1};
    margin: ${({ minimal }) => (minimal ? "0" : "5px -5px")};
  }

  .bp5-intent-danger {
    color: ${Colors.RED3};
    &:hover {
      color: ${Colors.RED3};
    }
  }

  .bp5-datepicker-year-select {
    min-width: 80px;
  }
  .bp5-datepicker-month-select {
    min-width: 130px;
  }
`;

// Add a new styled component for the content area
const PopoverContentContainer = styled.div<{ maxHeight?: string }>`
  max-height: ${({ maxHeight }) => maxHeight || "none"};
  overflow-y: auto;
`;

export function Popover({
  content,
  children,
  isOpen = false,
  onClose,
  interactionKind = "click",
  position = Position.BOTTOM_RIGHT,
  placement,
  canEscapeKeyClose = true,
  className,
  popoverClassName,
  minimal = false,
  hoverOpenDelay = 0,
  hoverCloseDelay = 0,
  arrowProps,
  modifiers = {
    arrow: { enabled: true },
    flip: { enabled: true },
  },
  onOpened,
  popupKind = "menu",
  autoFocus = false,
  transitionDuration = 200,
  targetProps,
  renderTarget,
  maxHeight,
  usePortal = false,
}: PopoverProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen);
  const arrowRef = useRef(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const hasArrow = modifiers?.arrow?.enabled && !minimal;
  const finalPlacement = placement ?? positionToPlacement[position];
  const flipEnabled = modifiers?.flip?.enabled ?? true;
  const xOffset = modifiers?.offset?.x ?? 0;
  const yOffset = modifiers?.offset?.y ?? 0;

  useEffect(() => {
    setIsPopoverOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isPopoverOpen) {
      if (autoFocus && contentRef.current) {
        // Focus the first focusable element in the popover
        const focusable = contentRef.current.querySelector<HTMLElement>(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        if (focusable) {
          focusable.focus();
        } else {
          // If no focusable element is found, focus the container itself
          // setTimeout to ensure the focus happens after the popover is rendered
          setTimeout(() => {
            contentRef.current?.focus();
          }, 0);
        }
      }
    }
  }, [isPopoverOpen, onOpened, autoFocus]);

  // set up middleware
  const middleware = [
    offset(hasArrow ? OFFSET_DISTANCE : 0),
    arrow({
      element: arrowRef,
    }),
  ];

  if (flipEnabled) {
    middleware.push(
      flip({
        flipAlignment: true,
        fallbackPlacements: getFallbackPlacements({ finalPlacement }),
      }),
    );
  }

  // shift must come after flip
  middleware.push(shift({ padding: ARROW_PADDING }));

  const { x, y, refs, strategy, context } = useFloating({
    placement: finalPlacement,
    open: isPopoverOpen,
    onOpenChange: (open) => {
      setIsPopoverOpen(open);
      open ? onOpened?.() : onClose?.();
    },
    middleware,
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.8)",
    },
    duration: {
      open: transitionDuration,
      close: 100,
    },
    common: ({ placement }) => ({
      transformOrigin: getTransformOrigin({ placement }),
      transitionTimingFunction: "cubic-bezier(0.54, 1.12, 0.38, 1.11)",
    }),
  });

  const hover = useHover(context, {
    enabled: interactionKind === "hover",
    delay: { open: hoverOpenDelay, close: hoverCloseDelay },
    handleClose: safePolygon(),
  });

  const click = useClick(context, {
    enabled: interactionKind === "click",
  });

  const dismiss = useDismiss(context, {
    escapeKey: canEscapeKeyClose,
    capture: true,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    dismiss,
  ]);

  const topPosition = y ? y + yOffset : 0;
  const leftPosition = x ? x + xOffset : 0;

  const popoverContent = (
    <>
      <PopoverContainer
        ref={(node) => {
          refs.setFloating(node);
          contentRef.current = node;
        }}
        style={{
          position: strategy,
          top: topPosition,
          left: leftPosition,
          zIndex: 9999,
          ...styles,
        }}
        minimal={minimal}
        maxHeight={maxHeight}
        className={`${Classes.POPOVER} ${popoverClassName || ""}`}
        {...getFloatingProps()}
        tabIndex={autoFocus ? 0 : undefined}
      >
        <PopoverContentContainer
          maxHeight={maxHeight}
          className={Classes.POPOVER_CONTENT}
        >
          {content}
        </PopoverContentContainer>
        {hasArrow !== false && (
          <FloatingArrow
            ref={arrowRef}
            context={context}
            width={20}
            height={20}
            fill={arrowProps?.fill || Colors.WHITE}
            stroke={arrowProps?.stroke || Colors.LIGHT_GRAY1}
            strokeWidth={0.5}
            d={SVG_ARROW_PATH}
          />
        )}
      </PopoverContainer>
    </>
  );

  return (
    <>
      {renderTarget ? (
        renderTarget({
          isOpen: isPopoverOpen,
          ref: refs.setReference,
          ...getReferenceProps(),
          className: `${Classes.POPOVER_TARGET} ${className || ""}`.trim(),
          "aria-haspopup": interactionKind === "hover" ? undefined : popupKind,
          ...targetProps,
        })
      ) : (
        <div
          ref={refs.setReference}
          {...getReferenceProps()}
          className={`${Classes.POPOVER_TARGET} ${className || ""}`.trim()}
          style={{ display: "inline-block" }}
          aria-haspopup={interactionKind === "hover" ? undefined : popupKind}
          {...targetProps}
        >
          {children}
        </div>
      )}
      {isMounted
        ? usePortal
          ? createPortal(popoverContent, document.body)
          : popoverContent
        : null}
    </>
  );
}
